<script>
export default {
  computed: {
    tabs() {
      return () => import(`@/components/Themes/${this.$cookies.get('theme') ?? 'default'}/Loyalty/Reward/Tabs`);
    }
  },
  methods: {},
};
</script>
<template>
  <component :is="tabs" v-if="tabs"></component>
</template>